















import Vue from 'vue'
import Component from 'vue-class-component'
import { Getter, Action } from 'vuex-class'
import dashboardHeader from '@/components/dashboard/header.vue'
import dashboardAside from '@/components/dashboard/aside.vue'
import dashboardContent from '@/components/dashboard/content.vue'
import globalMixins from '@/mixins/global.ts'

@Component({
  components: {
    dashboardHeader,
    dashboardAside,
    dashboardContent
  },
  mixins: [globalMixins]
})
export default class dashboardIndex extends Vue<globalMixins> {
  @Getter('history') history: Object
  @Getter('latestAnno') latestAnno: Object
  @Getter('status') status: Object[]
  @Getter('doctorStatus') doctorStatus: Object[]
  @Getter('userCreaters') userCreaters: Object[]
  @Getter('proDetail') proDetail: Object
  @Getter('caseTotal') caseTotal: number
  @Getter('doctorTotal') doctorTotal: number
  @Action('statsCase') statsCase: Function
  @Action('statsDoctor') statsDoctor: Function
  @Action('LatestAnnouncement') LatestAnnouncement: Function
  @Action('projectDetail') projectDetail: Function
  @Action('delLeader') delLeader: Function
  // data
  project: any = {}

  mounted () {
    this.project = JSON.parse(localStorage.getItem("projects"))
    let params = {projectId : this.project.id}
    this.statsCase({ params })
    this.statsDoctor({ params })
    this.LatestAnnouncement({ params })
    this.projectDetail({ params })
  }
  doDelLeader (tag) {
    let params = {
      projectId: this.project.id,
      leaderId: tag.id
    }
    this.delLeader({ params }).catch(() => {
      this.errorMsg('删除失败，请稍后重试！')
    })
  }
}
