




















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import medicalCase from './report/medicalCase.vue'
import doctor from './report/doctor.vue'
import dayAdd from './report/line.vue'
import count from './report/count.vue'

@Component({
  components: {
    medicalCase,
    doctor,
    dayAdd,
    count
  },
  props: {
    history: Object,
    caseTotal: Number,
    doctorTotal: Number,
    doctorStatus: Array,
    status: Array
  }
})
export default class DashboardContent extends Vue {
  @Prop()
  latestAnno: any
  // init data
  created: string = 'created'
  approved: string = 'approved'
  docIcon: string = 'yisheng'
  caseIcon: string = 'shouye1'
  medicalCase: string = 'medicalCase'
  doctor: string = 'doctor'
  account: any =  JSON.parse(localStorage.getItem("account"))
  projects: string = JSON.parse(localStorage.getItem('projects'))
  annoCreate: string = ''
  annoContent: string = ''

  @Watch ('latestAnno')
  onLatestAnnoChange (val: any, old: any) {
    if (val !== null) {
      this.annoCreate = val.created_at
      this.annoContent = val.content
    } else {
      this.annoContent = '暂无公告'
    }
  }
}
